header {
    background-color: #036;
    border-bottom: 2px solid #fcba19;
    padding: 0 65px 0 65px;
    color: #fff;
    display: flex;
    height: 65px;
    top: 0px;

    width: 100%;
  }
  
  header h1 {
    font-family: ‘BCSans’, ‘Noto Sans’, Verdana, Arial, sans-serif;
    font-weight: normal;  /* 400 */
    margin: 5px 5px 0 18px;
    visibility: hidden;
  }
  
  header .banner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 10px 0 0;
    /* border-style: dotted;
    border-width: 1px;
    border-color: lightgrey; */
  }
  
  header .other {
    display: flex;
    flex-grow: 1; 
    /* border-style: dotted;
    border-width: 1px;
    border-color: lightgrey; */
  }

  #authentication-button{
    display:flex;
    width: 100px;
    align-self: flex-end;
    margin-right: 1rem;
  }
  
  #logged-in-toggle{
    background-color: #036;
    border-color: #036;
  }

  /*
    These are sample media queries only. Media queries are quite subjective
    but, in general, should be made for the three different classes of screen
    size: phone, tablet, full.
  */
  
  @media screen and (max-width:599px){
    header h1{
      display: none;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 899px) {
    header h1 {
      font-size: calc(5px + 2vw);
      visibility: visible;
      display: flex;
    }
  }
  
  @media screen and (min-width: 900px) {
    header h1 {
      font-size: 2.0em;
      visibility: visible;
      display: flex;
    }
  }